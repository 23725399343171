import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { LinearProgress, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { clearDocumentToken, fetchDocumentToken } from "../actions/documentToken";
import { selectDocumentToken } from "../selectors/documentToken";
import { fetchDocument } from "../actions/document";
import { fetchVerificationDocument } from "../actions/verification";

const ReportDownloader = () => {

    const {token} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(fetchDocumentToken(token));

        return () => {
            dispatch(clearDocumentToken());
        }
    }, []);

    const {loading, data,error} = useSelector(selectDocumentToken)

    const handleDownload = (order,key) => {
        dispatch(fetchVerificationDocument(order,key));
    };

    return (        
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black">
                    <Box fontWeight={750}>Informe de resultados</Box>
                </Typography>
            </div>
            <div style={{ backgroundColor: '#f4f4f4', height: '8em', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1" textAlign="center" color="text.black" style={{marginBottom:'1em'}}>
                    <Box fontWeight={750}>Recorda que podes ver tu historial de resultados iniciando sesion.</Box>
                </Typography>
                <Link style={{ textDecoration: 'none' }} to={"/"}>
                    <Button variant="outlined"  style={{marginRight:'2em'}}>INICIAR SESION</Button>
                </Link>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '20em', padding: '3em' }}>
                    <div style={{ marginBottom: '1em' }}>
                        <Button disabled={loading||error} size="small" variant="contained" fullWidth onClick={() => handleDownload(data?.order?.number, data?.document?.key)}>Descargar informe</Button>
                    </div>
                    <div style={{ height: '1em' }}>
                        {
                            loading && <LinearProgress color="primary" />
                        }
                    </div>

                    {
                        error && 
                        <Typography variant="body1" textAlign="center" color="text.black" style={{marginBottom:'1em'}}>
                            {error}
                        </Typography>
                    }

                    <Typography variant="body2" textAlign="center" color="text.black" style={{marginBottom:'1em'}}>
                        Los resultados enviados por este medio son estrictamente confidenciales y están dirigidos exclusivamente al destinatario. 
                        La correcta visualización del PDF puede verse afectada por las configuraciones o compatibilidad del dispositivo utilizado. 
                        CEBAC SRL no asume responsabilidad por distorsiones, errores de formato o problemas técnicos que puedan alterar la presentación del documento.
                        Una vez recibido, el manejo, seguridad y privacidad de esta información son responsabilidad del receptor. 
                        Recomendamos utilizar dispositivos confiables y proteger el acceso a estos datos para garantizar su confidencialidad.
                    </Typography>

                </div>
            </div>
        </div>
    )
}

export default ReportDownloader;